import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { Router } from "@angular/router";
import { AuthenticationService } from "app/auth/authentication.service";
import { switchMap } from "rxjs";
import { DialogService } from "../dialog/dialog.service";
import { ResponsiveService } from "../responsive.service";

@Component({
    selector: "app-user-dropdown",
    templateUrl: "./user-dropdown.component.html",
    styleUrl: "./user-dropdown.component.scss",
    imports: [
        CommonModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
    ],
})
export class UserDropdownComponent {
    @Input() public accountIcon?: string | null;

    protected user$ = this.authenticationService.user$;
    protected isMobile$ = this.responsiveService.isMobile$;

    public constructor(
        private dialogService: DialogService,
        private authenticationService: AuthenticationService,
        private responsiveService: ResponsiveService,
        private router: Router,
    ) {}

    protected get accountIconOrDefault() {
        return this.accountIcon ?? "account_circle";
    }

    protected logout(event: MouseEvent) {
        event.preventDefault();

        this.dialogService
            .openConfirmation("Are you sure you would like to log out?")
            .pipe(
                switchMap(() => this.authenticationService.logout()),
                switchMap(() => this.router.navigateByUrl("/login")),
            )
            .subscribe();
    }
}
